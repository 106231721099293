.header-wrapperRedeem {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 5vh 5vw;
}

.titleRedeem {
  font-size: 2.5em;
  font-weight: 600;
  margin-bottom: 3vh;
}

.subtitleRedeem {
  font-size: 1.3em;
}

.content-wrapperRedeem {
  display: flex;
  align-items: flex-start;
}

.cardRedeem {
  background: #cee8ff;
  border-radius: 25px;
  margin: 2vw;
  margin-top: 0;
  width: 70%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 5vh 2vw;
  position: relative;
}

.step-headerRedeem {
  margin-left: 2vw;
  font-size: 1.3em;
  font-weight: 600;
}

.sumbitBottom {
  padding: 1vh 20px;
  font-size: 1.2em;
  border: none;
  border-radius: 100vw;
  color: #fff;
  background-color: #2e69ff;
  transition: 0.3s;
}

.scanBottom {
  background-color: #2fb69f;
  margin-left: 1vw;
  margin-bottom: 1vh;
}

.sumbitBottom:hover {
  transform: scale(1.1);
}

.sumbitBottom:active {
  transform: scale(1.05);
}

.sumbitBottom:disabled {
  background-color: rgba(0, 0, 0, 0.7);
  opacity: 0.5;
}

.wrongVideo {
  color: #383b3f;
  bottom: 0;
  position: absolute;
  margin-top: 5vh;
}

.wrongVideo span {
  color: #0064c2;
  cursor: pointer;
}

.videoLink {
  display: flex;
  align-items: center;
  color: #383b3f;
  opacity: 1;
  align-self: flex-start;
  margin-bottom: 12px;
}

.videoLink:visited {
  color: #383b3f;
  opacity: 0.8;
}

.videoDetails {
  color: #383b3f;
  display: flex;
  flex-direction: row;
  margin: 2vh 2vw;
  width: 100%;
}

.videoDetails div {
  flex: 1;
  margin: 2vh 2vw;
  position: relative;
}

.videoDetails div h2 {
  margin-bottom: 16px;
}

.videoDetails div span {
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 1024px) {
  .content-wrapperRedeem {
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
  }
  .cardRedeem {
    width: 90%;
  }
}

@media only screen and (max-width: 768px) {
  .videoDetails {
    flex-direction: column;
  }
  .titleRedeem {
    font-size: 2em;
    font-weight: 600;
    margin-bottom: 3vh;
  }

  .subtitleRedeem {
    font-size: 1em;
  }
}
