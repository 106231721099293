.LoginWrapper {
  height: 100%;
  width: 100%;
  position: fixed;
  background: linear-gradient(119.4deg, #cbdeff 0%, #7aafff 100%);
  display: flex;
  align-items: center;
  justify-content: center;
}

.LoginContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: auto;
  width: 26vw;
  background: #21395f;
  box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  position: relative;
  padding: 4vw;
  color: #fff;
}

.LoginTitle {
  font-size: 2em;
  font-weight: 100;
  font-family: "Asap";
}

.LoginSumbitButton {
  padding: 1vh 20px;
  font-weight: 100;
  font-size: 1.2em;
  background: #5c93e9;
  border-radius: 10px;
  margin-top: 2vh;
  transition: transform 0.2s;
  user-select: none;
  border: none;
}

.LoginSumbitButton:hover {
  transform: scale(1.1);
}

.LoginSumbitButton:active {
  transform: scale(1.05);
}

.LoginSumbitButton:disabled {
  background: rgba(255, 255, 255, 0.7);
  opacity: 0.8;
  color: #383b3f;
  transform: scale(1);
}

@media only screen and (max-width: 1025px) {
  .LoginContent {
    width: 70vw;
  }
}
