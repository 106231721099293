.apply {
  height: 100vh;
}
.ApplyBox {
  display: flex;
  flex-direction: row;
}
.JobApply {
  margin: 5vh 0 2.5vh 40px;
}
.JobApply h1 {
  color: #0074e0;
}

/* Splitter */
.Split {
  width: 2px;
  height: 100%;
  background: #383b3f;
  margin: 0 5vw;
}

/* Sec 2 */
.form {
  padding-bottom: 50vh;
}
