.ContainerFooter {
  position: relative;
  bottom: 0;
  background: #26262b;
  padding: 20px 20px 10px 20px;
  color: #fff;
  z-index: 98;
}

.logo {
  height: 72.63px;
}

.contentFooter {
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
}
.TitleFooterBox {
  margin-left: 20px;
}

.logoFooter {
  margin: 0 20px;
}

.copyright {
  text-align: center;
}

/* .social {
  position: absolute;
  right: 0;
  bottom: 0;
  padding: 10px 20px;
} */

.Location {
  margin-top: 10px;
}

@media only screen and (max-width: 768px) {
  .contentFooter {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  .Location,
  .phone,
  .email {
    font-size: 1em;
  }
  .TitleFooterBox {
    margin-top: 20px;
    margin-left: 0;
  }
  .Location {
    margin-top: 20px;
  }
}
