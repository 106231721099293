/* Intro */

.Intro {
  padding: 10vh 10vw 0 10vw;
  background: url(../image/Home/Home1.webp),
    linear-gradient(360deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.7)),
    linear-gradient(270deg, transparent, rgba(0, 0, 0, 0.6));
  background-blend-mode: color;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 100vh;
  display: flex;
  align-items: center;
  box-sizing: border-box;
}

.IntroBox {
  color: #fff;
  position: relative;
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.title {
  font-size: 3em;
  text-align: right;
  font-weight: 400;
}

.seperatorTitle {
  background: #fff;
  height: 0.25vh;
  width: 40%;
  margin: 5vh 0;
}

.ButtonIntro {
  color: #fff;
  font-size: 1.3em;
  padding: 10px 30px;
  border-radius: 25px;
  border: 2px solid #fff;
  cursor: pointer;
  user-select: none;
  background-color: transparent;
  transition: 0.5s;
}

.ButtonIntro:hover {
  background: rgba(255, 255, 255, 0.2);
}

.ButtonIntro:active {
  background: rgba(255, 255, 255, 1);
  transition: 0.1s;
  color: #383b3f;
}

/* New */

.titleNew {
  width: 100%;
  padding: 2vh 0;
  font-size: 2.5em;
  background-color: #c0580c;
  margin-top: 8vh;
  color: #fff;
  text-align: center;
}

/* Other */

@media only screen and (max-width: 1024px) {
  .IntroBox {
    width: 60%;
  }
}

@media only screen and (max-width: 768px) {
  .Intro {
    justify-content: center;
  }
  .IntroBox {
    width: 70%;
    flex-direction: center;
    align-items: center;
  }
  .title {
    font-size: 2.5em;
    text-align: center;
  }
}

@media only screen and (max-width: 593px) {
  .IntroBox {
    width: 100%;
  }
}
