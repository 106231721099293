button {
  font: 400 1em "Asap";
  background: transparent;
  border: none;
  padding: 0;
  color: #383b3f;
}

body {
  margin: 0;
  font-family: "Asap";
  background-color: #fff;
  height: 100%;
  width: 100%;
  overflow: overlay;
  overflow-x: hidden;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  font-weight: 400;
}

::-webkit-scrollbar {
  background: transparent;
  width: 15px;
}

::-webkit-scrollbar-button {
  height: 25vh;
}

::-webkit-scrollbar-track:hover {
  box-shadow: inset 0 0 5px 5px rgba(53, 58, 68, 0.2);
  border: solid 5px transparent;
  border-radius: 15px;
}

::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 5px 5px rgba(53, 58, 68, 0.5);
  border: solid 5px transparent;
  position: absolute;
  border-radius: 15px;
}

::-webkit-scrollbar-thumb:hover {
  box-shadow: inset 0 0 5px 5px rgba(53, 58, 68, 0.6);
}

::-webkit-scrollbar-thumb:active {
  box-shadow: inset 0 0 5px 5px rgb(53, 58, 68);
}

a:-webkit-any-link {
  text-decoration: none;
}

a:active {
  outline: none;
}
