.JobHeader {
  position: relative;
  padding: 0 10vw;
  height: 100vh;
  background: linear-gradient(
    120deg,
    rgba(56, 159, 255, 0.68),
    rgba(129, 194, 255, 0.68)
  );
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: #fff;
}

.JobDescription {
  display: flex;
  position: relative;
  flex-direction: column;
  margin: 5vh 5vw;
  color: #383b3f;
}

.NoJob {
  font-size: 3.7em;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
}

.NoJob span {
  display: inline;
  z-index: 2;
  color: #383b3f;
  border-radius: 40px;
  padding: 2vh 2vw;
  background: linear-gradient(
    230deg,
    rgba(95, 132, 235, 0.68),
    rgba(138, 170, 240, 0.68)
  );
}

.searchImg {
  position: absolute;
  z-index: 1;
}

.DownArrow {
  position: absolute;
  bottom: 2vh;
  border-radius: 50%;
  transition: 0.6s;
}

.DownArrow:hover {
  background-color: rgba(255, 255, 255, 0.3);
  transform: scale(1.1);
}

.DownArrow:active {
  background-color: rgba(255, 255, 255, 0.5);
  transform: scale(1.05);
  transition: 0.2s;
}

@media only screen and (max-width: 1177px) {
  .NoJob {
    font-size: 3em;
  }
}
@media only screen and (max-width: 954px) {
  .NoJob {
    font-size: 2.5em;
  }
  .searchImg {
    width: 600px;
  }
}
@media only screen and (max-width: 800px) {
  .NoJob {
    font-size: 2.2em;
  }
}
@media only screen and (max-width: 700px) {
  .NoJob {
    font-size: 2em;
  }
  .NoJob span {
    border-radius: 30px;
  }
}
@media only screen and (max-width: 636px) {
  .NoJob {
    font-size: 1.7em;
  }
  .searchImg {
    width: 450px;
  }
}

@media only screen and (max-width: 541px) {
  .NoJob {
    font-size: 1.4em;
  }
  .searchImg {
    width: 320px;
  }
}

@media only screen and (max-width: 446px) {
  .NoJob {
    font-size: 1.1em;
  }
}
