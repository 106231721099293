.sectionVid {
  display: flex;
  min-height: 100vh;
  flex-direction: row;
  justify-content: space-evenly;
}

.sectionVidV {
  display: flex;
  flex-direction: column;
}

.sectionDis {
  margin-bottom: 15vh;
  background-color: #eec120;
  padding: 2vh 0;
  border-radius: 5vh;
}

.disTitle {
  font-size: 2em;
  color: #fff;
  margin: 0 0 2vh 5vh;
}

.dis {
  min-height: 0;
}
