.Navbar {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  z-index: 99;
  padding: 2vh 20px;
  right: -15px;
  left: 0;
  transition: top 0.8s, background 0.5s, color 0.5s;
  box-sizing: border-box;
  width: 100vw;
  overflow: hidden;
}

.logoNav {
  height: 50px;
  margin-top: 10px;
}

.navBars {
  height: 40px;
}

.TitleNavBox {
  margin-left: 20px;
}

.TitleNav {
  font-size: 2em;
  font-weight: 400;
}

.SubtitleNav {
  font-size: 0.9em;
}

.Navheader {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.Nav {
  display: flex;
  flex-direction: row;
}

.Nav > * {
  padding: 0 1vw;
  font-size: 1.5em;
  cursor: pointer;
  text-decoration: none;
  color: inherit;
  user-select: none;
}

.NavDrawer > * {
  margin: 1vh 20px;
  font-size: 1.4em;
  margin-right: 0;
  transition: 0.2s;
  color: inherit;
}

.NavDrawer > *:hover {
  margin: 1vh 40px;
}

.NavDrawer > *:active {
  color: #e86301;
}

@media only screen and (max-width: 1023px) {
  .Nav > * {
    font-size: 1.3em;
  }
}

@media only screen and (max-width: 600px) {
  .logoNav {
    height: 40px;
  }
  .navBars {
    height: 30px;
  }
  .TitleNav {
    font-size: 1.5em;
  }
  .SubtitleNav {
    font-size: 0.9em;
  }
}
