.AboutHeader {
  position: relative;
  padding: 0 5vw;
  height: 100vh;
  background: #749390;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: #fff;
}

.AboutImage {
  box-sizing: border-box;
  margin-top: 8vh;
  border-radius: 2vw;
  width: 100%;
  height: 80vh;
  background: url(../image/About/about.webp),
    linear-gradient(360deg, rgba(0, 0, 0, 0.5), transparent),
    linear-gradient(270deg, rgba(0, 0, 0, 1), transparent);
  background-blend-mode: color;
  background-repeat: no-repeat;
  background-position: 30% 50%;
  background-size: cover;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 5vw;
}

.AboutImageContent {
  height: 80px;
  width: 80px;
  overflow: visible;
  position: relative;
  z-index: 2;
}

.AboutHeaderContent {
  position: relative;
  font-size: 2em;
  font-weight: 100;
  display: flex;
  flex-direction: row;
  align-items: center;
  transform: translateX(2vw);
}

.AboutHeaderTitle {
  margin-left: 3vw;
  width: 40vw;
}

@media only screen and (max-width: 700px) {
  .AboutHeaderContent {
    font-size: 1.5em;
    flex-direction: column;
    align-items: center;
    transform: translateX(0);
  }
  .AboutHeaderTitle {
    margin-top: 3vh;
    margin-left: 0;
    width: 80vw;
    text-align: center;
  }
  .AboutImage {
    justify-content: center;
  }
  .AboutImageContent {
    height: 60px;
    width: 60px;
  }
}
