.ProductHeader {
  position: relative;
  padding: 0 10vw;
  height: 100vh;
  background: linear-gradient(
    121.49deg,
    rgba(63, 173, 172, 0.6) 4.45%,
    rgba(151, 117, 146, 0.315625) 46.35%,
    rgba(161, 231, 138, 0.13) 92.86%
  );
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: #383b3f;
}

.TitleHeader {
  font-size: 6em;
  text-align: center;
  padding-top: 2vh;
}

.SubtitleHeader {
  font-size: 2.5em;
  text-align: center;
}

.ProductTitle {
  display: flex;
  font-size: 2.5em;
  font-weight: 100;
  justify-content: center;
  color: #c0580c;
  margin-bottom: 2vh;
}

.OtherProductContent {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin: 10vh 0;
}

.OtherProductContent > * {
  flex-direction: column;
}

.DividerProduct {
  width: 2px;
  background: #c0580c;
  border-radius: 1vw;
}

.ProductTitleBox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 10vh 0;
}

.underlineProduct {
  background: #c0580c;
  height: 4px;
  width: 5vw;
  border-radius: 1vw;
}

.Top3Product {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-around;
  flex-wrap: wrap;
}

.TitleBoxed {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Title {
  font-size: 2em;
  text-align: center;
  color: #c0580c;
  margin: 2vh 0;
}

.Clients {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  margin-bottom: 10vh;
  min-height: 300px;
}

.ClientDetail {
  display: flex;
  flex-direction: row;
}

.ClientLogo {
  height: 10vw;
  width: 10vw;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
}

.WhyUsTitle {
  color: #fff;
  font-size: 2em;
  margin-bottom: 2vw;
  font-weight: 700;
  text-align: center;
}

.WhyUsWrapper {
  width: 100%;
  background: linear-gradient(
    123.53deg,
    rgba(127, 127, 213, 0.68) 2.55%,
    rgba(134, 168, 231, 0.68) 44.58%,
    rgba(145, 234, 228, 0.69) 98.76%
  );
  border-radius: 5vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 2vw 0;
  margin: 5vw 0;
}

.WhyUsImage {
  border-radius: 4vw;
  width: 48%;
  height: 43vw;
  object-position: center;
  object-fit: cover;
  margin: 2vw 4vw;
}

.WhyUsBox {
  display: flex;
}

.WhyUsBoxWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin-right: 4vw;
}

.WhyUsBoxContent {
  display: flex;
  flex-wrap: wrap;
  max-width: 58.5vw;
}

.OurBrandsTitle {
  text-align: center;
  font-size: 2em;
  margin: 2vh 0;
  color: #c0580c;
}

.OurBrandsBox {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 5vh 5vw;
  box-sizing: border-box;
  background: rgba(187, 205, 221, 0.5);
  align-items: center;
  justify-content: space-evenly;
}

@media only screen and (max-width: 1357px) {
  .TitleHeader {
    font-size: 4em;
  }

  .SubtitleHeader {
    font-size: 2em;
  }
}
@media only screen and (max-width: 1199px) {
  .WhyUsImage {
    width: 90vw;
    height: 70vh;
  }
  .OtherProductContent {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .WhyUsBoxWrapper {
    margin-right: 0;
  }
  .WhyUsWrapper {
    flex-direction: column;
    align-items: center;
  }
  .WhyUsBoxContent {
    align-items: center;
    max-width: 64vw;
  }
}
@media only screen and (max-width: 904px) {
  .TitleHeader {
    font-size: 3em;
  }

  .SubtitleHeader {
    font-size: 1.7em;
  }
}
@media only screen and (max-width: 767px) {
  .WhyUsBoxContent {
    max-width: 74vw;
  }
}
@media only screen and (max-width: 686px) {
  .TitleHeader {
    font-size: 2.5em;
  }

  .SubtitleHeader {
    font-size: 1.3em;
  }
}
@media only screen and (max-width: 566px) {
  .TitleHeader {
    font-size: 1.5em;
  }

  .SubtitleHeader {
    font-size: 1em;
  }
}

@media only screen and (max-width: 532px) {
  .WhyUsBoxContent {
    max-width: 94vw;
  }
}

@media only screen and (max-width: 339px) {
  .TitleHeader {
    font-size: 1.3em;
  }

  .SubtitleHeader {
    font-size: 0.8em;
  }
}
